import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { DatabaseService } from 'src/_services/DatabaseService';
import { Location } from '@angular/common'
import { DialogComponent } from 'src/app/dialog.component';
import { sessionStorage } from 'src/app/localstorage.service';



@Component({
  selector: 'app-distributor-stock-detail',
  templateUrl: './distributor-stock-detail.component.html',
  styleUrls: ['./distributor-stock-detail.component.scss']
})
export class DistributorStockDetailComponent implements OnInit {
  skLoading: boolean = false;
  stock_upload: any = []
  id: any;
  loader: any;
  loginData: any;
  userType:any;



  constructor(public serve: DatabaseService, public session: sessionStorage, public toast: ToastrManager, public route: ActivatedRoute, public location: Location, public dialog: DialogComponent,public router : Router) {

    this.loginData = this.session.getSession();
    console.log(this.loginData.value.data.user_type)
    this.userType = this.loginData.value.data.user_type
    console.log(this.userType)
    

   }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params['id'];
      this.serve.currentUserID = params['id']
      if (this.id) {
        this.getStockDetails();
      }
    });
  }
  getStockDetails() {
    this.skLoading = true;

    setTimeout(() => {
      this.serve.post_rqst({ 'purchase_id': this.id }, "Stock/purchaseDetailForDistributor")
        .subscribe((result => {
          if (result['statusCode'] == 200) {
            this.skLoading = false;
            this.stock_upload = result.result['purchase_info']

          } else {
            this.toast.errorToastr(result['statusMsg']);
            this.skLoading = false;
          }
        }))
    }, 700);
  }
  back(): void {
    this.router.navigate(['/distributor-stock-list/']);
  }
  Update_qty(id, qty) {
    if (qty == 0) {
      this.toast.errorToastr(' Quantity should be greater than 0');
    }
    else{
      this.serve.post_rqst({ 'id': id, 'product_qty': qty,'type':'purchase' }, "Stock/editPurchaseSaleEntry").subscribe((result => {
        if (result['statusCode'] == 200) {
          this.toast.successToastr('Quantity Updated Successfully');
          this.getStockDetails();
        } else {
          this.toast.errorToastr('Something Went Wrong')
        }
  
      }))
    }
    
  }
  deletecategory(item_id, index) {
    this.dialog.confirm("You want to delet this item !").then((result) => {
      if (result) {
        this.serve.post_rqst({ 'id': item_id,'type':'purchase' }, "Stock/removePurchaseSaleEntry")
          .subscribe((result => {
            if (result['statusCode'] == 200) {
              this.toast.successToastr('Item Deleted Successfully');
              this.stock_upload.splice(index, 1)
              this.getStockDetails();
            } else {
              this.toast.errorToastr('Something Went Wrong')
            }

          }))
      }
    })
  }
}
