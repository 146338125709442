import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DialogComponent } from 'src/app/dialog.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DatabaseService } from 'src/_services/DatabaseService';
import { sessionStorage } from 'src/app/localstorage.service';
import { Location } from '@angular/common'
import { ToastrManager } from 'ng6-toastr-notifications';
import { StatusModalComponent } from '../status-modal/status-modal.component';



@Component({
  selector: 'app-secondary-order-detail',
  templateUrl: './secondary-order-detail.component.html'
})
export class SecondaryOrderDetailComponent implements OnInit {
  orderType: any = 'order';
  status: any;
  id: any;
  login_data: any = [];
  secondaryOrderDetail: any = [];
  distributorInfo: any = []
  order_logs: any = []
  item_details: any = []
  editqty: any = false;
  Order_Amount: any;
  login_dr_id: any;
  skLoading: boolean = false;

  constructor(public route: ActivatedRoute, public location: Location, public serve: DatabaseService, public dialog: MatDialog, public session: sessionStorage, public dialogs: DialogComponent, public toast: ToastrManager) {
    this.login_data = this.session.getSession();
    this.login_data = this.login_data.value.data;

    if (this.login_data.access_level != '1') {
      this.login_dr_id = this.login_data.id;
    }
    this.route.params.subscribe(params => {
      this.status = this.route.queryParams['_value']['status'];

    });

  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params['id'];
      // this.id = this.serve.crypto(params['id'],false);
      this.serve.currentUserID = params['id']
      // this.serve.currentUserID = this.serve.crypto(params['id'],false)
      if (this.id) {
        // this.loader = true;
        this.getSecondaryOrderDetails();
      }
    });
  }

  getSecondaryOrderDetails() {
    this.skLoading = true;
    setTimeout(() => {
      this.serve.post_rqst({ 'id': this.id }, "Order/secondaryOrderDetail")
        .subscribe((result => {
          if (result['statusCode'] == 200) {
            this.skLoading = false;
            this.secondaryOrderDetail = result['result']
            this.distributorInfo = result['result']['distributor_info']
            this.order_logs = result['result']['order_log']
            this.item_details = result['result']['item_info'];
            this.Order_Amount = Number(this.secondaryOrderDetail.order_total) + Number(this.secondaryOrderDetail.order_discount)
            this.item_details.map((row) => {
              row.editqty = false
              
            })
          } else {
            this.toast.errorToastr(result['statusMsg']);
            this.skLoading = false;
          }
        }))
    }, 700);
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(StatusModalComponent, {
      width: '400px', data: {
        order_status: this.secondaryOrderDetail.order_status,
        order_id: this.id,
        from: 'Secondary_order',
        reason: ''
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getSecondaryOrderDetails();
    });
  }
  edit_qty(row) {
    row.editqty = true;
  }
  deleteOrderitem(order_id, index) {
    this.dialogs.confirm("You want to delet this item !").then((result) => {
      if (result) {
        this.serve.post_rqst({ 'id': order_id }, "Order/secondaryOrderDeleteItem")
          .subscribe((result => {
            if (result['statusCode'] == 200) {
              this.toast.successToastr(result['statuMsg']);
              this.item_details.splice(index, 1)
              this.getSecondaryOrderDetails();
            } else {
              this.toast.errorToastr(result['statuMsg'])
            }

          }))
      }
    })
  }
  Update_qty(row, index) {
    this.calc(row, index);

    setTimeout(() => {
      let user_data = {
        'distributor_id': this.secondaryOrderDetail.delivery_from
      }
      this.serve.post_rqst({ 'order_id': this.secondaryOrderDetail.id, 'id': row.id, 'cart_data': this.item_details, 'user_data': user_data }, "Order/secondaryOrderUpdateItem").subscribe((result => {
        if (result['statusCode'] == 200) {
          this.getSecondaryOrderDetails();
          this.toast.successToastr('Quantity Updated Successfully');
        }
        else {
          this.item_details[index].editqty   = false;
          this.toast.errorToastr(result['statusMsg']);
        }
      }))
    }, 1000);

  }
  back(): void {
    this.location.back()
  }

  exportPdf() {
    this.skLoading = true;
    let id = { 'id': this.id }
    this.serve.post_rqst(id, "Order/exportSecondaryOrderPdf").subscribe((result) => {
      if (result['statusCode'] == 200) {
        this.skLoading = false;
        window.open(this.serve.uploadUrl + 'orderPdf/' + result['file_name']);
      } else {
        this.skLoading = false;
        this.toast.errorToastr(result['statusMsg'])
      }
    }
      , err => {
        this.skLoading = false;

      }
    )
  }

  calc(row, index) {
    console.log(row, index);
    this.item_details[index].amount = parseFloat(this.item_details[index].net_price) * parseFloat(this.item_details[index].qty);
    if (this.secondaryOrderDetail.gst_type == 'Gst Paid') {
      this.item_details[index].gst_amount = parseFloat(this.item_details[index]['amount']) - ((((this.item_details[index]['amount'] * 100))) / (parseFloat(this.item_details[index]['gst_percent'] + 100)))
      this.item_details[index].total_amount = parseFloat(this.item_details[index].amount);
    } else {
      this.item_details[index].gst_amount = ((parseFloat(this.item_details[index].amount) * parseFloat(this.item_details[index].gst_percent)) / 100);
      this.item_details[index].total_amount = parseFloat(this.item_details[index].gst_amount) + parseFloat(this.item_details[index].amount);
    }
    this.secondaryOrderDetail.total_order_qty = 0;
    this.secondaryOrderDetail.total_order_amount = 0;
    this.secondaryOrderDetail.sub_total = 0;
    this.secondaryOrderDetail.order_discount = 0;
    this.secondaryOrderDetail.order_total = 0;
    this.secondaryOrderDetail.order_gst = 0;

    for (let i = 0; i < this.item_details.length; i++) {
      this.secondaryOrderDetail.total_order_qty += parseFloat(this.item_details[i].qty);
      this.secondaryOrderDetail.total_order_amount += (parseFloat(this.item_details[i].qty) * parseFloat(this.item_details[i].price));
      this.secondaryOrderDetail.sub_total += (parseFloat(this.item_details[i].qty) * parseFloat(this.item_details[i].net_price));
      this.secondaryOrderDetail.order_discount += (parseFloat(this.item_details[i].qty) * parseFloat(this.item_details[i].discount_amount));
      this.secondaryOrderDetail.order_total += parseFloat(this.item_details[i].amount);
      this.secondaryOrderDetail.order_gst += parseFloat(this.item_details[i].gst_amount);
    }
    if (this.secondaryOrderDetail.gst_type == 'Gst Extra') {
      this.secondaryOrderDetail.order_grand_total = (parseFloat(this.secondaryOrderDetail.sub_total) + (this.secondaryOrderDetail.order_gst));
    } else {
      this.secondaryOrderDetail.order_grand_total = (parseFloat(this.secondaryOrderDetail.sub_total));

    }

  }

}
